
// 导出页面为PDF格式
import html2canvas from 'html2canvas'
import { saveAs } from 'file-saver'

export default (id, name) => {
  var element = document.getElementById(id)
  html2canvas(element, {
    logging: false,
    scale: 5, //设置放大的倍数
    width: element.scrollWidth,
    height: element.scrollHeight,
    windowWidth: element.scrollWidth + 20,
    windowHeight: element.scrollHeight + 20,
  }).then(function (canvas) {
    canvas.toBlob(function(blob) {
        saveAs(blob, name)
    });
  });
}
