<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input v-model="listQuery.keyword" placeholder="Email / ReferralCode" style="width: 200px;" class="filter-item" clearable/>
      <el-button class="filter-item" type="primary" icon="el-icon-search" @click="search">Search</el-button>
    </div>
    <div v-if="data" class="container">
      <div id="nodeTree" class="tree-container text-center" style="text-align: center">
        <el-tree
          :data="data"
          node-key="Id"
          default-expand-all
          :render-content="renderContent"
        >
        </el-tree>
      </div>
    </div>
  </div>
</template>
<script>
import request from '@/utils/request'
import htmlToPng from "@/utils/htmlToPng"
export default {
  data () {
    return {
      listQuery: {
        keyword: '', 
      },
      data: null,
    }
  },
  created () {
  },
  methods: {
    search() {
      this.getReferralTree()
    },
    getReferralTree() {
      request({
        url: '/api/admin/report/referralTree',
        method: 'get',
        params: this.listQuery
      })
      .then((response) => {
        this.data = response.data.Data.children
        this.expandChange()
      })
    },
    renderContent(h, { node, data, store }) {
      console.log(data);
      return (
        <span class="custom-tree-node">
          <span>Level-{data.level}: {data.Email}</span>
          <span>Total Referral: {data.TotalReferral}</span>
          <span>Active: {data.Active ? 'true' : 'false'}</span>
          <span>Trade Volume: {data.TotalTradeVolume}</span>
        </span>);
    },
    handleExport() {
      htmlToPng("nodeTree", `node-${this.listQuery.userId}.png`);
    }
  }
}
</script>
<style rel="stylesheet/scss" lang="scss">
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
  background: #409EFF;
  color: #FFFFFF;
  padding: 4px 8px;
  border-radius: 16px;
}
</style>
